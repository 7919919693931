import { Button, Column, Icon, ModalV2, Row } from '@/components';
import { theme } from '@/components/App';
import { AgoraLinkTypeE, AgoraPlanNameE } from '@/types/cyclone/models';
import React, { FunctionComponent } from 'react';
import { Control, Controller, FieldValues } from 'react-hook-form';
import { LinkInput } from '../components';
import { useAuth } from '@/contexts';

interface LinksEditModalPropsI {
  type?: AgoraLinkTypeE | null;
  setType: (type: AgoraLinkTypeE | null) => void;
  control: Control<FieldValues>;
  errors: { title?: string; url?: string };
  onSubmit: () => void;
}

export const LinksEditModal: FunctionComponent<LinksEditModalPropsI> = ({
  type,
  setType,
  control,
  errors,
  onSubmit
}) => {
  const { session } = useAuth();
  const isEventPlan = session?.vendor?.plan_name === AgoraPlanNameE.EVENT;
  const getSubTitle = () => {
    switch (type) {
      case AgoraLinkTypeE.CUSTOM:
        return 'Link personalizado';
      case AgoraLinkTypeE.SERVICE:
        return 'Servicios';
      case AgoraLinkTypeE.QUICK_BOOKING:
        return 'Reservar';
      case AgoraLinkTypeE.SUBSCRIPTION:
        return 'Plan mensual';
      case AgoraLinkTypeE.EVENT:
        return isEventPlan ? 'Eventos' : 'Capacitaciones';
      case AgoraLinkTypeE.AGENDA:
        return 'Agenda';
      case AgoraLinkTypeE.DONATION:
        return 'Bancar';
      case AgoraLinkTypeE.DIGITAL_CONTENT:
        return 'Descargables';
      case AgoraLinkTypeE.ABOUT:
        return 'Sobre mi';
      case AgoraLinkTypeE.YOUTUBE:
        return 'Agregar contenido multimedia';
      case AgoraLinkTypeE.MAPS:
        return 'Ubicación';
    }
  };

  const getIcon = () => {
    switch (type) {
      case AgoraLinkTypeE.CUSTOM:
        return <Icon name="custom_link" width="17" height="17" color={theme.colors.grey[700] as string} />;
      case AgoraLinkTypeE.YOUTUBE:
        return <Icon name="youtube_not_bg" color="#FF0000" width="17" height="17" />;
      default:
        return <Icon name="agora_logo_outline" width="17" height="17" color={theme.colors.black as string} />;
    }
  };

  const isValidLink = () => {
    return 'Url invalida';
  };

  return (
    <ModalV2
      show={!!type}
      onClose={() => {
        setType(null);
      }}
    >
      <Column className="h-full">
        <h1 className="text-lg font-semibold mb-5">
          {type === AgoraLinkTypeE.CUSTOM ? 'Agregar Link' : 'Editar Link'}
        </h1>
        <Row align="center" gap={5} className="mb-6">
          <div>{getIcon()}</div>

          <p className="text-base font-normal">{getSubTitle()}</p>
        </Row>
        <Controller
          name="title"
          control={control}
          rules={{
            required: { value: true, message: 'Este campo es requerido' }
          }}
          render={({ field: { onChange, value } }) => (
            <LinkInput
              name="title"
              id="title-link"
              onChange={onChange}
              placeholder={type == AgoraLinkTypeE.CUSTOM ? 'Título del link' : 'Título de la sección'}
              error={errors.title || ''}
              value={value || ''}
              showCount
              maxLength={40}
            />
          )}
        />
        {(type == AgoraLinkTypeE.CUSTOM || type == AgoraLinkTypeE.YOUTUBE) && (
          <Controller
            name="url"
            control={control}
            rules={{
              required: { value: true, message: 'Este campo es requerido' },
              validate: isValidLink
            }}
            render={({ field: { onChange, value } }) => (
              <LinkInput
                name="url"
                id="url-link"
                onChange={onChange}
                placeholder={type == AgoraLinkTypeE.YOUTUBE ? 'Link del video de Youtube' : 'URL'}
                error={errors.url || ''}
                value={value || ''}
              />
            )}
          />
        )}
        <Button fullWidth onClick={onSubmit}>
          Guardar
        </Button>
      </Column>
    </ModalV2>
  );
};
