import React from 'react';
import DatePicker, { ReactDatePickerProps } from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import dayjs from 'dayjs';
import classNames from 'classnames';
import { Row } from '../Row';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendarAlt } from '@fortawesome/free-solid-svg-icons';
import { es } from 'date-fns/locale';

type InputDatepickerProps = {
  date?: Date | string;
  setDate: (date: Date) => void;
  datepickerProps?: Partial<ReactDatePickerProps>;
  customInput?: boolean;
  className?: string;
};

const TODAY_DATE = new Date();

export const InputDatepicker: React.FC<InputDatepickerProps> = ({
  date,
  setDate,
  datepickerProps,
  customInput = true,
  className
}) => {
  const [selectedDate, setSelectedDate] = React.useState<Date | null>(null);

  React.useEffect(() => {
    try {
      if (date) {
        const parsed = dayjs(date).toDate();
        if (dayjs(parsed).isValid()) {
          setSelectedDate(parsed);
        } else {
          setSelectedDate(null);
          setDate(TODAY_DATE);
        }
      } else {
        setSelectedDate(null);
        setDate(TODAY_DATE);
      }
    } catch (error) {
      console.error('Error parsing date:', error);
      setSelectedDate(null);
      setDate(TODAY_DATE);
    }
  }, [date, setDate]);

  const CustomInput = React.forwardRef<HTMLDivElement, { value: string; onClick: () => void }>(
    ({ onClick }, ref) => (
      <Row
        align="center"
        justify="space-between"
        className={classNames(
          'bg-white cursor-pointer text-sm transition-colors rounded p-4 h-10 w-full border border-[#212121]/40 hover:border-[#0072FB]',
          { 'text-[#212121]': selectedDate },
          { 'text-gray-500': !selectedDate },
          {
            'text-gray-400 bg-transparent border-gray-400 cursor-default hover:border-gray-400':
              datepickerProps?.disabled
          }
        )}
        ref={ref}
        onClick={onClick}
      >
        {selectedDate ? dayjs(selectedDate).format('DD/MM/YYYY') : 'Selecciona'}
        <FontAwesomeIcon size="sm" fixedWidth icon={faCalendarAlt} />
      </Row>
    )
  );

  const handleDateChange = (date: Date | null) => {
    if (date && dayjs(date).isValid()) {
      setDate(date);
      setSelectedDate(date);
    }
  };

  return (
    <DatePicker
      selected={selectedDate}
      onChange={handleDateChange}
      dateFormat="dd/MM/yyyy"
      minDate={datepickerProps?.minDate || TODAY_DATE}
      locale={es}
      customInput={customInput ? <CustomInput value="" onClick={() => {}} /> : undefined}
      {...datepickerProps}
      className={classNames(className)}
    />
  );
};
