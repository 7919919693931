import { useEffect } from 'react';

declare global {
  interface Window {
    // @ts-ignore
    Intercom?: any;
    // @ts-ignore
    intercomSettings?: any;
  }
}

export const useIntercom = (shouldDisplay: boolean = true) => {
  useEffect(() => {
    // Check if Intercom is available
    if (typeof window.Intercom !== 'undefined') {
      if (!shouldDisplay) {
        // Shutdown Intercom
        window.Intercom('shutdown');

        // Remove the Intercom frame
        const frame = document.querySelector('#intercom-frame');
        if (frame) {
          frame.remove();
        }

        // Remove the Intercom container
        const container = document.querySelector('#intercom-container');
        if (container) {
          container.remove();
        }
      } else {
        // Restart Intercom if it was previously shutdown
        window.Intercom('boot', window.intercomSettings);
      }
    }

    return () => {
      // Cleanup when component unmounts
      if (typeof window.Intercom !== 'undefined' && !shouldDisplay) {
        window.Intercom('shutdown');
      }
    };
  }, [shouldDisplay]);
};
