import {
  PatternE,
  RoundedE,
  SubtitleTypeFontE,
  TitleTypeFontE,
  TypeButtonsE
} from '@/containers/StoreFrontBuilder/context/storefront';
import { SizeImageE } from '@/types/cyclone/models';

export const getTemplateConfig = (variant: string) => {
  switch (variant) {
    case 'template_1': // Beauty 1 - Cejas y Pestañas
      return {
        has_cover: false,
        has_avatar: true,
        background_color: '#1E3E3A',
        title_color: '#FFFFFF',
        button_color: '#FFFFFF',
        text_color: '#1E3E3A',
        shadow_color: '#1E3E3A',
        font_type: SubtitleTypeFontE.POPPINS,
        title_font_type: TitleTypeFontE.RALEWAY,
        button_type: TypeButtonsE.SOFT_SHADOW,
        border_radius_px: RoundedE.ROUNDED_30,
        pattern_type: null,
        is_banner_displayed: false,
        is_avatar_displayed: true,
        size_banner: SizeImageE.BIG,
        size_avatar: SizeImageE.BIG
      };

    case 'template_2': // Beauty 2 - Centro de Estética
      return {
        has_cover: false,
        has_avatar: true,
        background_color: '#C8C5C1',
        title_color: '#FFFFFF',
        button_color: '#FFFFFF',
        text_color: '#FFFFFF',
        shadow_color: '#FFFFFF',
        font_type: SubtitleTypeFontE.POPPINS,
        title_font_type: TitleTypeFontE.RALEWAY,
        button_type: TypeButtonsE.OUTLINE,
        border_radius_px: RoundedE.ROUNDED_0,
        pattern_type: null,
        is_banner_displayed: false,
        is_avatar_displayed: true,
        size_banner: SizeImageE.BIG,
        size_avatar: SizeImageE.BIG
      };

    case 'template_3': // Beauty 3 - Peluquería
      return {
        has_cover: false,
        has_avatar: true,
        background_color: '#FFFFFF',
        title_color: '#4E4845',
        button_color: '#EBE9E7',
        text_color: '#4E4845',
        shadow_color: '#C1BEBC',
        font_type: SubtitleTypeFontE.POPPINS,
        title_font_type: TitleTypeFontE.PLAYFAIR,
        button_type: TypeButtonsE.DARK_SHADOW,
        border_radius_px: RoundedE.ROUNDED_10,
        pattern_type: null,
        is_banner_displayed: false,
        is_avatar_displayed: true,
        size_banner: SizeImageE.BIG,
        size_avatar: SizeImageE.BIG
      };

    case 'template_4': // Beauty 4 - Manicuría
      return {
        has_cover: false,
        has_avatar: true,
        background_color: `linear-gradient(to top, #DAE2F8, #D6A4A4)`,
        title_color: '#FFFFFF',
        button_color: '#FFFFFF',
        text_color: '#D6A4A4',
        shadow_color: '#DAE2F8',
        font_type: SubtitleTypeFontE.POPPINS,
        title_font_type: TitleTypeFontE.PLAYFAIR,
        button_type: TypeButtonsE.SOLID,
        border_radius_px: RoundedE.ROUNDED_10,
        pattern_type: null,
        is_banner_displayed: false,
        is_avatar_displayed: true,
        size_banner: SizeImageE.BIG,
        size_avatar: SizeImageE.BIG
      };

    case 'template_5': // Beauty 5 - Barbería
      return {
        has_cover: false,
        has_avatar: true,
        background_color: '#000000',
        title_color: '#FFFFFF',
        button_color: '#FFFFFF',
        text_color: '#000000',
        shadow_color: '#000000',
        font_type: SubtitleTypeFontE.POPPINS,
        title_font_type: TitleTypeFontE.RALEWAY,
        button_type: TypeButtonsE.SOLID,
        border_radius_px: RoundedE.ROUNDED_30,
        pattern_type: null,
        is_banner_displayed: false,
        is_avatar_displayed: true,
        size_banner: SizeImageE.BIG,
        size_avatar: SizeImageE.BIG
      };

    case 'template_6': // Beauty 6 - Depilación
      return {
        has_cover: false,
        has_avatar: true,
        background_color: '#C2CDD9',
        title_color: '#FFFFFF',
        button_color: '#FFFFFF',
        text_color: '#B1BFCE',
        shadow_color: '#C8C8C8',
        font_type: SubtitleTypeFontE.POPPINS,
        title_font_type: TitleTypeFontE.RALEWAY,
        button_type: TypeButtonsE.DARK_SHADOW,
        border_radius_px: RoundedE.ROUNDED_30,
        pattern_type: null,
        is_banner_displayed: false,
        is_avatar_displayed: true,
        size_banner: SizeImageE.BIG,
        size_avatar: SizeImageE.BIG
      };

    case 'template_7': // Beauty 7 - Maquillaje
      return {
        has_cover: false,
        has_avatar: true,
        background_color: `linear-gradient(to top, #FFFFFF, #DE6789)`, // Modified to use correct gradient format
        title_color: '#FFFFFF',
        button_color: '#FFFFFF',
        text_color: '#DE6789',
        shadow_color: '#E47494',
        font_type: SubtitleTypeFontE.POPPINS,
        title_font_type: TitleTypeFontE.PLAYFAIR,
        button_type: TypeButtonsE.DARK_SHADOW,
        border_radius_px: RoundedE.ROUNDED_30,
        pattern_type: null,
        is_banner_displayed: false,
        is_avatar_displayed: true,
        size_banner: SizeImageE.BIG,
        size_avatar: SizeImageE.BIG
      };

    case 'template_8': // Beauty 8 - Estética Integral
      return {
        has_cover: false,
        has_avatar: true,
        background_color: '#D8CFC2',
        title_color: '#FFFFFF',
        button_color: '#FFFFFF',
        text_color: '#C1B6A8',
        shadow_color: '#C1B6A8',
        font_type: SubtitleTypeFontE.POPPINS,
        title_font_type: TitleTypeFontE.PLAYFAIR,
        button_type: TypeButtonsE.SOFT_SHADOW,
        border_radius_px: RoundedE.ROUNDED_10,
        pattern_type: null,
        is_banner_displayed: false,
        is_avatar_displayed: true,
        size_banner: SizeImageE.BIG,
        size_avatar: SizeImageE.BIG
      };

    case 'template_9': // Beauty 9 - Spa Integral
      return {
        has_cover: false,
        has_avatar: true,
        background_color: '#F6F6F6',
        title_color: '#57735B',
        button_color: '#FFFFFF',
        text_color: '#57735B',
        shadow_color: '#57735B',
        font_type: SubtitleTypeFontE.POPPINS,
        title_font_type: TitleTypeFontE.RALEWAY,
        button_type: TypeButtonsE.DARK_SHADOW,
        border_radius_px: RoundedE.ROUNDED_10,
        pattern_type: null,
        is_banner_displayed: false,
        is_avatar_displayed: true,
        size_banner: SizeImageE.BIG,
        size_avatar: SizeImageE.BIG
      };

    case 'template_10': // Beauty 10 - Medicina Estética
      return {
        has_cover: false,
        has_avatar: true,
        background_color: '#FFFFFF',
        title_color: '#525252',
        button_color: '#757575',
        text_color: '#757575',
        shadow_color: '#757575',
        font_type: SubtitleTypeFontE.POPPINS,
        title_font_type: TitleTypeFontE.RALEWAY,
        button_type: TypeButtonsE.OUTLINE,
        border_radius_px: RoundedE.ROUNDED_30,
        pattern_type: null,
        is_banner_displayed: false,
        is_avatar_displayed: true,
        size_banner: SizeImageE.BIG,
        size_avatar: SizeImageE.BIG
      };
    case 'template_13':
      return {
        has_cover: true,
        has_avatar: true,
        background_color: '#FFFFFF',
        title_color: '#375928',
        button_color: '#375928',
        text_color: '#FFF',
        shadow_color: '#000000',
        font_type: SubtitleTypeFontE.POPPINS,
        title_font_type: TitleTypeFontE.POPPINS,
        button_type: TypeButtonsE.SOLID,
        border_radius_px: RoundedE.ROUNDED_0,
        pattern_type: null,
        is_banner_displayed: true,
        is_avatar_displayed: true,
        size_banner: SizeImageE.SMALL,
        size_avatar: SizeImageE.SMALL
      };
    case 'template_14':
      return {
        has_cover: true,
        has_avatar: true,
        background_color: '#780700',
        title_color: '#FFF',
        button_color: '#FFF',
        text_color: '#868686',
        shadow_color: '#000000',
        font_type: SubtitleTypeFontE.PLAYFAIR,
        title_font_type: TitleTypeFontE.PLAYFAIR,
        button_type: TypeButtonsE.SOLID,
        border_radius_px: RoundedE.ROUNDED_30,
        pattern_type: null,
        is_banner_displayed: true,
        is_avatar_displayed: true,
        size_banner: SizeImageE.SMALL,
        size_avatar: SizeImageE.SMALL
      };
    case 'template_15':
      return {
        has_cover: true,
        has_avatar: true,
        background_color: '#FFFFFF',
        title_color: '#FFFFFF',
        button_color: '#FFFFFF',
        text_color: '#3B9648',
        shadow_color: '#000000',
        font_type: SubtitleTypeFontE.POPPINS,
        title_font_type: TitleTypeFontE.POPPINS,
        button_type: TypeButtonsE.SOFT_SHADOW,
        border_radius_px: RoundedE.ROUNDED_10,
        pattern_type: PatternE.PATTERN_1,
        is_banner_displayed: true,
        is_avatar_displayed: true,
        size_banner: SizeImageE.BIG,
        size_avatar: SizeImageE.SMALL
      };
    case 'template_16':
      return {
        has_cover: false,
        has_avatar: true,
        background_color: '#780700',
        title_color: '#FFF',
        button_color: '#FFF',
        text_color: '#FFF',
        shadow_color: '#000000',
        font_type: SubtitleTypeFontE.POPPINS,
        title_font_type: TitleTypeFontE.MERIENDA,
        button_type: TypeButtonsE.OUTLINE,
        border_radius_px: RoundedE.ROUNDED_30,
        pattern_type: PatternE.PATTERN_12,
        is_banner_displayed: false,
        is_avatar_displayed: true,
        size_banner: SizeImageE.SMALL,
        size_avatar: SizeImageE.SMALL
      };
    default:
      return {
        has_cover: false,
        has_avatar: true,
        background_color: '#FFFFFF',
        title_color: '#FFFFFF',
        button_color: '#FFFFFF',
        text_color: '#212121',
        shadow_color: '#000000',
        font_type: SubtitleTypeFontE.RALEWAY,
        title_font_type: TitleTypeFontE.RALEWAY,
        button_type: TypeButtonsE.SOLID,
        border_radius_px: RoundedE.ROUNDED_30,
        pattern_type: PatternE.PATTERN_5,
        is_banner_displayed: true,
        is_avatar_displayed: true,
        size_banner: SizeImageE.BIG,
        size_avatar: SizeImageE.BIG
      };
  }
};
