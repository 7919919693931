import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useNavbarItems, useWindowResize } from '@/hooks';
import { Logo, ResponsiveContainer, Row } from '@/components';
import { useAuth } from '@/contexts';
import { ProfileDropdownMenu } from '../Navbar/components';
import { driver } from 'driver.js';
import 'driver.js/dist/driver.css';

const STEPS = {
  PAYMENT: {
    id: 'payment',
    title: 'Configuración de pago',
    description: 'Configura tus métodos de pago para empezar a recibir pagos',
    path: '/ajustes/medios-de-pago'
  },
  SCHEDULE: {
    id: 'schedule',
    title: 'Configuración de horarios',
    description: 'Establece tus horarios de disponibilidad',
    path: '/agenda'
  },
  SERVICE: {
    id: 'service',
    title: 'Crea tu primer servicio',
    description: 'Configura los servicios que ofreces',
    path: '/servicio/crear'
  }
} as const;

const RestrictedHeader = () => {
  const { session } = useAuth();
  const { isMobileSize } = useWindowResize();
  const navigate = useNavigate();
  const path = useLocation().pathname;
  const { dropdownItems } = useNavbarItems(path);

  const getCurrentStep = () => {
    const { vendor } = session || {};
    if (!vendor) return null;

    const {
      first_payment_method_at: isPaymentConfigured,
      first_availability_at: isScheduleConfigured,
      first_service_at: isServiceCreated
    } = vendor;

    if (!isPaymentConfigured) return STEPS.PAYMENT;
    if (!isScheduleConfigured) return STEPS.SCHEDULE;
    if (!isServiceCreated) return STEPS.SERVICE;
    return null;
  };

  const getCompletedStepsCount = () => {
    const { vendor } = session || {};
    if (!vendor) return 0;

    return [vendor.first_payment_method_at, vendor.first_availability_at, vendor.first_service_at].filter(
      Boolean
    ).length;
  };

  const initializeDriver = (currentStep: any) => {
    const driverObj = driver({
      popoverClass: 'driverjs-theme',
      stagePadding: 4,
      showButtons: ['next'],
      doneBtnText: 'Ir ahora',
      onNextClick: () => {
        if (currentStep.path === '/agenda') {
          navigate('/agenda', { state: { addWorkingHours: true } });
        } else {
          navigate(currentStep.path);
        }
      },
      steps: [
        {
          element: '#driver-currentNavbarStep',
          popover: {
            side: 'bottom',
            title: currentStep.title,
            description: currentStep.description
          }
        }
      ]
    });

    return driverObj;
  };

  React.useEffect(() => {
    // const currentStep = getCurrentStep();
    // if (!currentStep) return;

    // if (window.location.pathname.includes(currentStep.path)) {
    //   return;
    // }

    // const elementToHighlight = document.getElementById('driver-currentNavbarStep');
    // if (!elementToHighlight) return;

    const driverObj = initializeDriver(currentStep);
    // driverObj.drive();

    return () => {
      driverObj.destroy();
    };
  }, [session?.vendor, path]);

  const handleStepClick = () => {
    const currentStep = getCurrentStep();
    if (!currentStep) return;

    const driverObj = initializeDriver(currentStep);
    driverObj.drive();
  };

  if (!session?.vendor) {
    return <header className="sticky top-0 left-0 h-[48px] right-0 bottom-0 border-b bg-gray-200 z-50" />;
  }

  const currentStep = getCurrentStep();
  const completedSteps = getCompletedStepsCount();

  return (
    <header className="sticky top-0 left-0 h-[48px] right-0 bottom-0 border-b bg-white z-50">
      <ResponsiveContainer>
        <Row align="center" justify="space-between" className="w-full h-[48px]">
          {isMobileSize ? (
            <Logo type="triangle" width="34px" height="48px" />
          ) : (
            <Logo type="horizontal" width="100px" height="48px" />
          )}
          {currentStep && (
            <div
              id="driver-currentNavbarStep"
              onClick={handleStepClick}
              className="flex gap-2 items-center cursor-pointer"
            >
              <span className="text-xs sm:text-sm font-medium text-gray-500 px-2 py-0.5 border rounded-full">
                Paso {completedSteps + 1} de 3
              </span>
              <span className="text-xs sm:text-sm font-medium hover:underline underline-offset-1">
                {currentStep.title}
              </span>
            </div>
          )}
          <ProfileDropdownMenu avatar={`${session.avatar_url}_250.webp`} dropdownItems={dropdownItems} />
        </Row>
      </ResponsiveContainer>
    </header>
  );
};

export default RestrictedHeader;
